import React,{FC,useState,useLayoutEffect,useRef} from 'react'
import s from './styles/sport.module.css'
//import calnd from './images/calendar.svg'
import {SportsT,ProtocolT} from '../../types/cmsTypes'
import Btn from './Btn'
import ReactMarkdown from 'react-markdown'

type SportT={
   data:SportsT
   table:ProtocolT[]
   selectedDate:any
   filterTable:any
   header:any
   tableLoaded:boolean
   strapiPages:any
   strapiTable:any
   setFakeLoad:any
   fakeLoad:any
}



const Sport:FC<SportT> = ({fakeLoad,setFakeLoad,table,selectedDate,filterTable,header,tableLoaded,strapiPages,strapiTable}) => {
   
   
   const[openReg, setOpenReg]=useState(false)
   const containerTableItemB = useRef<any>(null)
   const containerTableItemS = useRef<any>(null)

   const tabClick=(e:any)=>{
      //containerTableItemB.current&&containerTableItemB.current.scrollIntoView({block: "start",behavior : "smooth"})
      //containerTableItemS.current&& containerTableItemS.current.scrollIntoView({block: "start",behavior : "smooth"})
      if(e.target.id==strapiTable.button[strapiTable.button.length-1].text){
         setOpenReg(true)
      }else{
         setOpenReg(false)
      }
      
      window.scrollTo({
         top:0,
         left:0,
         behavior:"smooth"
      })
      
      
      
   }

   const options:any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timezone: 'UTC'
   };

   const  dayNum = String(new Date().getDate()).padStart(2, '0')
   const  monthNum = String(new Date().getMonth() + 1).padStart(2, '0')
   const  yearNum = String(new Date().getFullYear())
   let newDateforInput = `${yearNum}-${monthNum}-${dayNum}`
	const [date,setDate]=useState(newDateforInput)
   
   const [fakeLoadBtn,setFakeLoadBtn]=useState<any>(true)
   
   const changeDate=(e:React.FormEvent<HTMLInputElement>)=>{



      if(e.currentTarget.value){
         
         const  dayNum = String(new Date(e.currentTarget.value).getDate()).padStart(2, '0')
         const  monthNum = String(new Date(e.currentTarget.value).getMonth() + 1).padStart(2, '0')
         const  yearNum = String(new Date(e.currentTarget.value).getFullYear())
         let newDateforInput = `${yearNum}-${monthNum}-${dayNum}`
         

      setDate(newDateforInput)
      selectedDate(newDateforInput.split('-').reverse().join('.'))

      
      }else{

         const  dayNum = String(new Date().getDate()).padStart(2, '0')
         const  monthNum = String(new Date().getMonth() + 1).padStart(2, '0')
         const  yearNum = String(new Date().getFullYear())
         let newDateforInput = `${yearNum}-${monthNum}-${dayNum}`
         setDate(newDateforInput)
      }

      setFakeLoad(false)
   }
   const arrowClick=(side:string)=>{
      
      setFakeLoad(false)
      
      if(side=='left'){
            let dl = new Date(date)
            dl.setDate(dl.getDate() - 1)
            const  dayNum = String(dl.getDate()).padStart(2, '0')
            const  monthNum = String(dl.getMonth() + 1).padStart(2, '0')
            const  yearNum = String(dl.getFullYear())
            let newDateforInput = `${yearNum}-${monthNum}-${dayNum}`
            setDate(newDateforInput)
            selectedDate(newDateforInput.split('-').reverse().join('.'))
      }
      if(side=='right'){
            let dr = new Date(date)
            dr.setDate(dr.getDate() + 1)
            
            const  dayNum = String(dr.getDate()).padStart(2, '0')
            const  monthNum = String(dr.getMonth() + 1).padStart(2, '0')
            const  yearNum = String(dr.getFullYear())
            let newDateforInput = `${yearNum}-${monthNum}-${dayNum}`
            setDate(newDateforInput)
            selectedDate(newDateforInput.split('-').reverse().join('.'))
      }


   
   }

   const normalDate:any  = new Date(date.split('.').reverse().join('-')).toLocaleString("ru", options).split(' ').slice(0,2).join(' ')

   
   //const btnref = useRef<any>(null);
    useLayoutEffect(() => {
      //containerTableItemS.current&& containerTableItemS.current.scrollIntoView({block: "start",behavior : "smooth"})
      window.scrollTo({
         top:0,
         left:0,
         behavior:"smooth"
      })
      
     }, [fakeLoad,fakeLoadBtn]);

   return (
      
      <div className={s.body}>
         
         <div >
            <div  className=' flex items-center justify-center sm:pb-10 pb-5'>
               <img className='object-cover sm:h-64 h-48' src={'https://microfootball.ru/app'+strapiPages.logo.data.attributes.url} alt="logo" />
            </div>

            <div   className='pb-5'>
               <h1  className='text-white text-center'>{strapiPages.title}</h1>
            </div>
         </div>
      
         <div  className='relative flex items-center justify-between flex-wrap  pb-6'>
               
               

                  <div  id='sportButtons' className={'flex flex-nowrap overflow-auto  md:overflow-hidden sm:rounded w-fit mx-auto '+s.containerBtn}>
                     <Btn setFakeLoadBtn={setFakeLoadBtn} filterTable={filterTable} tabClick={tabClick}  buttons={strapiTable.button} date={date} />
                     
                  </div>
                  
            
         </div>
         
         <div ref={containerTableItemS} className={s.container}>
                  

                  {
                     openReg ? 
                     <div>
                        <div  className={'whitespace-pre-line  hidden md:block '+s.containerReg}>
                           <ReactMarkdown>
                              
                              {strapiPages.reglament}
                           
                           </ReactMarkdown>
                        </div>
                     </div>
                     :

                     fakeLoad ? 
                     <div >
                        {
                           table.length>0 ?
                           <>
                           
                           <table className={s.tableB}>
                           <thead className={s.thead}>
                              <tr className={s.tr }>
                           
                                    {
                                       header.map((item:any)=>(
                                          <th key={item.id} className={s.theadCol }>
      
                                                <div className={s.theadColContainer}>
      
                                                   {
                                                      (item.text.includes(header[0].text)) && 
                                                      
                                                      <form >
                                                         
                                                            {/* <img className='absolute top-1/2 -translate-y-1/2 left-0 pointer-events-none ' src={calnd} alt="c" /> */}
                                                            <input className='bg-blue-solightbg text-center   text-blue-solight font-semibold' onChange={(e)=>changeDate(e)} type="date" name="calendar" value={date}/>
                                                         
                                                      </form> 
                                                   }
                                                   {
                                                      item.text.includes(header[header.length-1].text) ?
                                                      <span  className=' font-semibold relative after:-translate-x-1/2 after:left-1/2 after:top-full after:h-0.5 after:w-full after:bg-blue-solight after:absolute after:content-[""]'>{item.text}</span>
                                                      :
                                                      item.text.includes(header[0].text) ?
                                                      <span className='font-semibold hidden '>{item.text}</span>
                                                      :
                                                      <span className='font-semibold '>{item.text}</span>
                                                   }
      
                                                </div>
                                                
                                          </th>
                                       ))
                                    }
      
                              </tr>
                           </thead>
                           </table >
                           <div  className={s.containerTable + ' hidden md:block'}>
                           <table className={s.tableB}>
                           <tbody>
                                 {
                        
                              table.map(item=>(
                                    <tr key={item.id} className=" border-grey-light border-b ">
                                    <td scope="row" className={s.tddate+"  py-2 font-medium text-blue-solight  whitespace-nowrap "}>
                                       <p >{normalDate}</p>
                                    </td>
                                    <td className="py-2 text-blue-solight font-medium">
                                       <p >{item.date[1]}</p>
                                    </td>
                                    <td className="py-2.5">
                                       {
                              // APi Mistake
                                          item.winner ==  item.awayTeam ?
                              
                                          <div className='flex flex-col items-center text-left'>
                                                
                                                <div className='w-52 pl-2 py-1 flex item-center justify-start py-1 font-bold  text-black '><img  className='mr-2 w-6 h-6 ' src={item.homeLogo} alt="logo" /><p className='flex items-center justify-start' >{item.homeTeam}</p> </div>
                                                <div className='w-52 pl-2 py-1 flex item-center justify-start py-1  text-black '><img className='mr-2  w-6 h-6' src={item.awayLogo} alt="logo" /><p className='flex items-center justify-start' >{item.awayTeam}</p> </div>
                              
                                          </div>
                              
                              
                                          :
                                          item.winner ==  item.homeTeam ?
                                             
                                             <div className='flex flex-col items-center text-left'>
                                                
                                                   <div className='w-52 pl-2 py-1 flex item-center justify-start py-1  text-black '><img  className='mr-2 w-6 h-6 ' src={item.homeLogo} alt="logo" /><p className='flex items-center justify-start' >{item.homeTeam}</p> </div>
                                                   <div className='w-52 pl-2 py-1 flex item-center justify-start py-1 font-bold  text-black '><img className='mr-2  w-6 h-6' src={item.awayLogo} alt="logo" /><p className='flex items-center justify-start' >{item.awayTeam}</p> </div>
                              
                                             </div>
                              
                                          :
                                          
                                          <div className='flex flex-col items-center text-left'>
                                                
                                                <div className='w-52 pl-2 py-1 flex item-center justify-start py-1  text-black '><img  className='mr-2 w-6 h-6 ' src={item.homeLogo} alt="logo" /><p className='flex items-center justify-start' >{item.homeTeam}</p> </div>
                                                <div className='w-52 pl-2 py-1 flex item-center justify-start py-1  text-black '><img className='mr-2  w-6 h-6' src={item.awayLogo} alt="logo" /><p className='flex items-center justify-start' >{item.awayTeam}</p> </div>
                              
                                          </div>
                              
                                       }
                                    </td>
                                    <td className="py-2">
                                       {
                                          item.status !=='Не начат'?
                                          <>
                                          {/* Mistake in api */}
                                             <p className='font-bold'>{item.awayScore}</p>
                                             <p className='font-bold'>{item.homeScore}</p>

                                          </>:
                                          <>
                                          <p className='font-bold'>-</p>
                                          <p className='font-bold'>-</p>
                                          </>
                                       }
                                       
                              
                                    </td>
                                    <td className="py-2">
                                       {
                                          item.times !=''?
                                          <>
                                             <p className='font-normal text-blue-light'>({item.times[0][0]})</p>
                                             <p className='font-normal text-blue-light'>({item.times[0][1]})</p>
                                          </>:
                                          <>
                                          <p>-</p>
                                          <p>-</p>
                                          </>
                                          
                              
                                       }
                                       
                              
                                    </td>
                                    <td className="py-2">
                                       {
                                          item.times !=''?
                                          <>
                                             <p className='font-normal text-blue-light'>({item.times[1][0]})</p>
                                             <p className='font-normal text-blue-light'>({item.times[1][1]})</p>
                                          </>:
                                          <>
                                          <p>-</p>
                                          <p>-</p>
                                          </>
                                          
                              
                                       }
                                       
                              
                                    </td>
                                    
                                    
                              
                                    <td className="py-2 ">
                                       {
                                          item.status==''? <div className={s.live}><img className='animate-opas' src={item.statusImg} alt='i' /><div className='text-red ml-2 font-medium px-1 uppercase pt-0.5'>Live</div></div>
                                          :
                              
                                          item.status=='Завершен'?
                                          <div className={s.live} ><img src={item.statusImg} alt="i" /><div className='text-green ml-2 font-medium uppercase pt-0.5'>{item.status}</div></div>
                              
                                          :
                                          item.status=='Не начат'?
                                          <p className=' font-тщкьфд '>{item.status}</p>
                              
                              
                                          :
                                          <p className={s.complite}>{item.status}</p>
                                       }
                                       
                                    </td>
                              
                              
                                    </tr>
                                    ))
                              
                                    
                                 }
      
            
                  </tbody>
                           </table >
                           </div>
                          

                        </>

               :
               <>
               <table className={s.tableB + ' rounded  '+ s.thead}>
                  <tbody>
                  
                              <tr className={s.tr }>
                           
                                    {
                                       header.map((item:any)=>(
                                          <th key={item.id} className={s.theadCol }>
      
                                                <div className={s.theadColContainer}>
      
                                                   {
                                                      (item.text.includes(header[0].text)) && 
                                                      
                                                      <form >
                                                         
                                                            {/* <img className='absolute top-1/2 -translate-y-1/2 left-0 pointer-events-none ' src={calnd} alt="c" /> */}
                                                            <input className='bg-blue-solightbg text-center   text-blue-solight font-semibold' onChange={(e)=>changeDate(e)} type="date" name="calendar" value={date}/>
                                                         
                                                      </form> 
                                                   }
                                                   {
                                                      item.text.includes(header[header.length-1].text) ?
                                                      <span  className=' font-semibold relative after:-translate-x-1/2 after:left-1/2 after:top-full after:h-0.5 after:w-full after:bg-blue-solight after:absolute after:content-[""]'>{item.text}</span>
                                                      :
                                                      item.text.includes(header[0].text) ?
                                                      <span className='font-semibold hidden '>{item.text}</span>
                                                      :
                                                      <span className='font-semibold '>{item.text}</span>
                                                   }
      
                                                </div>
                                                
                                          </th>
                                       ))
                                    }
      
                              </tr>
                           
                  </tbody>
               </table>
               
               <div > 
                  <div className={'pt-6 text-blue-solight h-80  items-start justify-center md:flex hidden '+s.containerNoM}>Нет матчей
                     </div>
               </div>
               </>
               

                        }

                        

                     </div>
                     :
                     <div className={'text-blue-solight animate-pulse hidden md:flex items-start p-20 justify-center '+s.containerReg}>

                        <div className={s.ldsroller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                     </div>
                  }



                  {  openReg ? 

                     <div>
                           <div className={'whitespace-pre-line py-4 md:hidden '+s.containerReg}>
                                 <ReactMarkdown>
                                 {strapiPages.reglament}
                              </ReactMarkdown>
                           </div>
                        
                     </div>
                     :

                     fakeLoad ?
                     <div >
                     <form  className='pb-3 md:hidden '>
                        <div className='flex py-2 items-center justify-between border border-blue-solightbg rounded'>
                           <img onClick={()=>arrowClick('left')} className='p-3 cursor-pointer ' src='https://microfootball.ru/app/uploads/arrow_L_91068969d2fe74d3aa48468a81657cdd_b8e657eb2d.svg?updated_at=2022-08-30T09:36:02.306Z' alt="arr" />
                           <input className='bg-white text-center    text-blue-solight font-semibold' onChange={(e)=>changeDate(e)} type="date" name="calendar" value={date}/>
                           {/* <img className='pointer-events-none absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-16 ' src={calnd} alt="c" /> */}
                           <img onClick={()=>arrowClick('right')}  className='p-3 cursor-pointer ' src='https://microfootball.ru/app/uploads/arrow_R_6597e56b7abfa91145af7882ee4775cd_92be5ad13e.svg?updated_at=2022-08-30T09:36:20.925Z' alt="arr" />
                        </div>
                        
                     </form>

                        {

                  table.length>0 ?
                  <>
                  <table  className={s.tableS}>
                     <thead className={s.thead}>
                        <tr className={s.tr }>
                              {  
                                 
                                 header.map((item:any)=>(
                                    
                                       (!item.text.includes('Тайм')&&!item.text.includes('Дата')) &&
                                       <th key={item.id} className={s.theadCol }>

                                          <div className={s.theadColContainer }>

                                             {
                                                item.text.includes(header[header.length-1].text) && <img src='https://microfootball.ru/app/uploads/table_904eac5fd2b715dc705cb2c23db13fee_da0916a470.svg?updated_at=2022-08-25T13:42:08.174Z' alt="i" />
                                             }

                                             {
                                                !item.text.includes(header[header.length-1].text)  && <span className='font-semibold '>{item.text}</span>
                                             }
                                             

                                          </div>
                                          
                                    </th> 
                                       
                                    
                                 ))
                              }

                        </tr>
                     </thead>
                  </table>   
                  <div className={s.containerTable + ' md:hidden' }>
                  <table className={s.tableS}>
                  <tbody>

                     {
                        
                        table.map(item=>(
                              <tr key={item.id} className=" border-grey-light border-b tableSTr">
                              
                              <td className="py-2 text-blue-solight font-medium">
                                 <p >{item.date[1]}</p>
                              </td>
                              <td className="py-2.5 ">
                              {
                              // APi Mistake

                              item.winner ==  item.awayTeam ?
                                 <div className='flex flex-col items-center text-left '>
                                    <div className='justify-start w-40 py-1 flex item-center text-black font-bold'><img className='mr-2  w-6 h-6' src={item.homeLogo} alt="logo" /><p className='flex items-center  leading-4'>{item.homeTeam}</p></div>
                                    <div className='justify-start w-40 py-1 flex item-center text-black '><img className='mr-2  w-6 h-6' src={item.awayLogo} alt="logo" /><p className='flex items-center  leading-4'>{item.awayTeam}</p></div>

                                 </div>


                                 :
                                 item.winner ==  item.homeTeam ?
                                 <div className='flex flex-col items-center text-left '>
                                    <div className='justify-start w-40 py-1 flex item-center text-black '><img className='mr-2  w-6 h-6' src={item.homeLogo} alt="logo" /><p className='flex items-center  leading-4'>{item.homeTeam}</p></div>
                                    <div className='justify-start w-40 py-1 flex item-center text-black font-bold'><img className='mr-2  w-6 h-6' src={item.awayLogo} alt="logo" /><p className='flex items-center  leading-4'>{item.awayTeam}</p></div>

                                 </div>
                                 :
                                 <div className='flex flex-col items-center text-left '>
                                    <div className='justify-start w-40 py-1 flex item-center text-black '><img className='mr-2  w-6 h-6' src={item.homeLogo} alt="logo" /><p className='flex items-center  leading-4'>{item.homeTeam}</p></div>
                                    <div className='justify-start w-40 py-1 flex item-center text-black '><img className='mr-2  w-6 h-6' src={item.awayLogo} alt="logo" /><p className='flex items-center  leading-4'>{item.awayTeam}</p></div>

                                 </div>
                              }
                              </td>
                              <td className="py-2">
                              {
                                 item.status !=='Не начат'?
                                 <>
                                 {/* MIstake in Api */}
                                    <p className='font-bold'>{item.awayScore}</p>
                                    <p className='font-bold'>{item.homeScore}</p>
                                 </>:
                                 <>
                                 <p className='font-bold'>-</p>
                                 <p className='font-bold'>-</p>
                                 </>
                              }

                              </td>

                              <td className="py-2 ">
                              {
                     item.status==''? <div className={s.live}><img className='animate-opas' src={item.statusImg} alt='i' /></div>
                     :

                     item.status=='Завершен'?
                     <div className={s.live} ><img src={item.statusImg} alt="i" /></div>

                     :
                     item.status=='Не начат'?
                     <p className=' font-тщкьфд '>-</p>

                     :
                     <p className={s.complite}>{item.status}</p>
                     }
                                 
                              </td>


                        </tr>
                        ))
                        
                     }

      </tbody>
                  </table>
                  </div>
                  



                  </>
                  
                  
                  
                  :
               
               <div > 
                  <div className={s.containerNoM+' pt-6 text-blue-solight flex items-start justify-center md:hidden'}>Нет матчей
                     </div>
               </div>
               }
                     </div>

                  :
                  <div className={'text-blue-solight animate-pulse md:hidden flex items-start p-20 justify-center '+s.containerReg}>

<div className={s.ldsroller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                  </div>
                     }


                  
         </div> 

		</div>
      
		
   )
}

export default Sport