import React,{FC,useEffect,useState,useRef} from 'react'
import s from './styles/main.module.css'
import './styles/swiperstyles.css'
import Live from './Live';
import NewsComp from '../../components/NewsComp/NewsComp'
import { Swiper, SwiperSlide } from "swiper/react";

import { Link } from 'react-router-dom';
import { useLoadingContext } from "react-router-loading";
import {Helmet} from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import 'swiper/css/autoplay';
// import required modules
import { Navigation,Autoplay,Scrollbar,Pagination} from "swiper";


const Main:FC = () => {
  const fetchUrl = "https://microfootball.ru/app/api/main?populate[banners][populate][banner][fields]=url&populate[banners][populate][smallBanner][fields]=url&populate[live][fields]&populate[news][populate][button][fields]"
  const [isLoaded, setIsLoaded] = useState(false);

  const[mainInfo, setMainInfo]=useState<any>()
  
  const[lives,setLives]=useState([])
  const loadingContext = useLoadingContext()
  const swiper = useRef<any>()
  const onInit  = (Swiper:any):void=>{
    
    swiper.current = Swiper
  }
  const loading = async () => {
    
    loadingContext.done();
};
const fetching = async () =>{
  try {
    const res = await fetch(fetchUrl)
    const result = await res.json()
    setMainInfo(result.data.attributes)

  } catch (error) {
    console.log(error)
  }

  setIsLoaded(true)
    setTimeout(()=>{
      loading()
      window.scrollTo(0,0)

      swiper.current.autoplay.running=true
      swiper.current.autoplay.start()
    },200)
}
  useEffect(()=>{
    fetching()
    // .then(()=>{
    //   fetch("/api/live/")
    //   .then(res=>{
    //     if(res.ok && res.json)return res.json()
    //   })
    //   .then(json=>{
        
    //     if(json.is_success){
  
    //       setIsLoaded(true)
    //       setLives(json.data)

    //       setTimeout(()=>{
    //         loading()
    //         window.scrollTo(0,0)
  
    //         swiper.current.autoplay.running=true
    //         swiper.current.autoplay.start()
    //       },200)


    //     }

    //   })
    // }

    // )
    // .catch((err) => {
    //   console.error(err)
    // })

    


  },[])

  if(!isLoaded || !mainInfo){
    return null
  }else{
    return (
      <div className={s.body} >
        <Helmet>
          <title>Main | MicroFootball</title>
          
        </Helmet>
        
        <section className='relative px-3'>
          <div className={`swiper-button-prev  main-slider after:hidden`}>
            <img src="https://microfootball.ru/app/uploads/arrl_72e09bb3cf.svg?updated_at=2022-08-25T10:40:03.141Z" alt="arr" />
          </div>
          <div className='px-3 absolute scroll-container'>
            <div className='relative px-3'>
              <div className="swiper-scrollbar main-slider scrollB  overflow-hidden"></div>
            </div>
  
            
          </div>
          
          <div className="swiper-button-next main-slider after:hidden">
            <img src="https://microfootball.ru/app/uploads/arr_R_3f2ea2504e.svg?updated_at=2022-08-25T10:40:03.073Z" alt="arr" />
          </div>
  
  
          <Swiper onInit={onInit}
          scrollbar={{ draggable: true,el:'.scrollB'}}
          navigation={{
            nextEl: '.swiper-button-next.main-slider',
            prevEl: '.swiper-button-prev.main-slider',}
          } 
          spaceBetween={0}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
  
          }}
  
          
          speed= {1200}
          modules={[Navigation,Autoplay,Scrollbar]} 
          className="main-slider swiper big  rounded">
            <div className="swiper-wrapper main-slider ">
                {
                  mainInfo.banners.map((item:any)=>(
                      <SwiperSlide key={item.id} className=" swiper-slide main-slider">
                        <Link  to={item.link}>

                        <img className='banner'  src={'https://microfootball.ru/app'+item.banner.data.attributes.url}  alt='banner'/>
                        <img  className='smallBanner' src={'https://microfootball.ru/app'+item.smallBanner.data.attributes.url}  alt='banner'/>
                        </Link>
                      </SwiperSlide>
                      
                  ))
                  
                }
                
            </div>
          </Swiper>
  
        </section>
        
  
  
        
        <section>
  
          <div>
            
              <Live live={mainInfo.live} lives={lives}/>
  
          </div>
  
          
        </section>
        
        {/* <section className='md:-mb-10'>
          <NewsComp defaultPages={5}/>
          
        </section> */}
      </div>
    )
  }
}

export default Main